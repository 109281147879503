<template>
  <div class="mr-5">
    <div class="card">
      <div>
        <a-button
          class="mt-4 mr-4"
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn mt-4"
          style="margin-right: 20px; margin-bottom: 20px; float: right"
          :labels="{
            serial: 'Numero de serie',
          }"
          :data="csvToExport"
          :name="'listeReglementAnnulé.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div class="card-body">
        <div>
          <a-table
            @change="tableChanged"
            :pagination="true"
            :data-source="data"
            :columns="columns"
            :scroll="{ x: 'max-content' }"
            rowKey="_id"
          >
            <template
              v-for="col in ['clientName', 'nomEnfant', 'date']"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.key, col)"
                />
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
                >{{ $t("paiement.chercher") }}</a-button
              >
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
                >{{ $t("action.reinitialiser") }}</a-button
              >
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>{{ text }}</template>
            </template>
            <a-tag color="#f50" slot="userName" slot-scope="text">
              {{ text ? text : "--" }}
            </a-tag>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import moment from "moment";
import JsonCsv from "vue-json-csv";

export default {
  components: {
    JsonCsv,
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        return {
          serial: item.serial,
          nomClient: item.clientName,
          dateAnnulation: item.date,
          montantTTC: item.money,
          TypeDeReglement: item.type,

          datePaiement: item.date,
        };
      });
    },
  },
  created() {
    apiClient.post("/cancellations/filter", { query: {} }).then((res) => {
      this.data = res.data.map((elem) => ({
        ...elem,
        type: (elem.activity ? "Activité/" : "Scolarité/") + elem.name,
        date: moment(elem.date).format("DD-MM-YYYY"),
      }));
      this.filtredTable = this.data.map((item) => {
        item.montantTTC = item.money.toFixed(2);
        return item;
      });
    });
    this.cacheData = this.data.map((item) => ({ ...item }));
  },
  data() {
    return {
      editingKey: "",
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      value: 1,
      loadingData: false,
      indeterminate: true,
      columns: [
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nomParent"),
          dataIndex: "clientName",
          key: "clientName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName",
          },
          onFilter: (value, record) =>
            record.clientName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.dateAnnulation"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            record.date.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "money",
          key: "money",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "money",
          },
          onFilter: (value, record) => {
            String(record.money).toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "type",
          key: "type",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) =>
            record.type.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },

        {
          title: this.$t("recette.cancelledBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) => {
            return record.userName
              ? record.userName.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    generatePdf() {
      this.$gtag.event("Imp listeReglementAnnulé", {
        event_category: "Impression PDF",
        event_label: "comptabilite:listeReglementAnnulé",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Annulation réglement";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 70, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let sommeTTC = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].serial);
        arr.push(this.filtredTable[i].clientName);
        arr.push(this.filtredTable[i].type);
        arr.push(this.filtredTable[i].money);
        sommeTTC += parseFloat(this.filtredTable[i].money);
        arr.push(this.filtredTable[i].date);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 80 },
        head: [
          [
            "Num",
            "Nom parent",
            "Type réglement",
            "Montant TTC",
            "Date d'annulation",
          ],
        ],
        body: array,
        foot: [
          [
            {
              colSpan: 3,
              content: "Total : ",
              styles: { halign: "center" },
            },
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      doc.save("Annulation_réglement.pdf");
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
